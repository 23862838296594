import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Alert from '@mui/material/Alert';
import Loading from 'components/common/Loading/Loading';
import Switch from 'components/common/Switch';
import {
  useCompanyDataAccessPermissionsGive,
  useCompanyDataAccessPermissionsRemove,
} from 'hooks/useCompanyDataAccessPermissions';
import useSnackbarMessage from 'hooks/useSnackbarMessage';
import Stack from 'components/common/Stack';
import { useEffect } from 'react';
import { Typography } from '@mui/material';
import useDataAccessList from 'hooks/useAccessRequests';

function CustomNoRowsOverlay() {
  const { t } = useTranslation();
  return (
    <Box p={4} textAlign={'center'}>
      {t('No companies.')}
    </Box>
  );
}

const ConfidentialDataAccessTable = () => {
  const { t } = useTranslation('common');
  const snackbarMessage = useSnackbarMessage();

  const { data, refetch, isRefetching, isLoading, isError } =
    useDataAccessList();

  const {
    isError: isErrorGive,
    isLoading: isLoadingGive,
    mutate: givePermission,
  } = useCompanyDataAccessPermissionsGive();
  const {
    isError: isErrorRemove,
    isLoading: isLoadingRemove,
    mutate: removePermission,
  } = useCompanyDataAccessPermissionsRemove();

  useEffect(() => {
    if (isErrorRemove || isErrorGive) {
      snackbarMessage.addMessage({ message: t('Something went wrong.') });
    }
  }, [snackbarMessage, isErrorGive, isErrorRemove]);

  const companies = data || [];
  const rows = companies.map((buyer: any) => ({
    name: buyer.name,
    id: buyer.id,
    slug: buyer.slug,
    has_received_access: buyer.has_received_access,
  }));

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('Company Name'),
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Stack spacing={2} direction={'row'}>
          <Typography variant="body1">{params.row.name}</Typography>
        </Stack>
      ),
    },
    {
      field: 'access',
      headerName: t('Access'),
      sortable: false,
      renderCell: (params) => (
        <Stack spacing={1}>
          <Switch
            disabled={isRefetching}
            label={''}
            checked={params?.row.has_received_access ? true : false}
            onChange={
              isLoadingGive || isLoadingRemove
                ? () => false
                : params?.row.has_received_access
                ? () => {
                    if (
                      confirm(
                        t(
                          'Are you sure you want to remove Data Access for {{company}}?',
                          { company: params.row.name }
                        )
                      )
                    ) {
                      removePermission({
                        id: params?.row.id,
                        onSuccess: () => refetch(),
                      });
                    }
                  }
                : () =>
                    givePermission({
                      id: params?.row.id,
                      onSuccess: () => refetch(),
                    })
            }
          />
        </Stack>
      ),
    },
  ];

  return isLoading ? (
    <Loading />
  ) : isError ? (
    <Alert severity="error">{t('There was a problem')}</Alert>
  ) : (
    <Box sx={{ height: '100%', minHeight: '240px', width: '100%' }}>
      <DataGrid
        sx={{
          '& .MuiDataGrid-cell': {
            display: 'flex',
            alignItems: 'center',
          },
        }}
        rows={rows}
        autoHeight
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
        }}
        slots={{ noRowsOverlay: CustomNoRowsOverlay }}
        pageSizeOptions={[5]}
        disableColumnMenu
        hideFooter
        disableRowSelectionOnClick
      />
    </Box>
  );
};
export default observer(ConfidentialDataAccessTable);
