import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import useUserCompanyCollectionOverview from 'hooks/useUserCompanyCollectionOverview';

import useUserCompany from 'hooks/useUserCompany';
import useSnackbarMessage from 'hooks/useSnackbarMessage';

import CollectionListDataGrid from './CollectionListDataGrid';

function UserCompanyCollectionList({
  collectionId,
  hasCollections,
  data,
}: {
  collectionId?: string;
  hasCollections?: boolean;
  data: any[];
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const snackbarMessage = useSnackbarMessage();
  const { userCompany } = useUserCompany();
  const [searchParams] = useSearchParams();

  const { isLoading } = useUserCompanyCollectionOverview({
    collection_id: collectionId,
  });

  const dedupeByName = (data: any[]): any[] => {
    const seenNames = new Set<string>();
    return data.filter((item) => {
      if (!item) return false;
      if (seenNames.has(item.company_name)) {
        return false;
      }
      seenNames.add(item.company_name);
      return true;
    });
  };

  const rows = dedupeByName(data).map((d: any) => ({
    id: d.company_uuid,
    company_name: d.company_name,
    company_slug: d.company_slug,
    company_sector: d.company_sector,
    company_country: d.company_country,
    company_logo: d.company_logo,
    company_website: d.company_website,
    reporting_company_name: d.reporting_company_name,
    reporting_company_slug: d.reporting_company_slug,
    reporting_company_sector: d.reporting_company_sector,
    reporting_company_country: d.reporting_company_country,
    climate_score:
      d.reporting_company_climate_score_overall ||
      d.company_climate_score_overall,
    maturity_level:
      (d.reporting_company_public_data_screening_last_date &&
        d.reporting_company_maturity_level) ||
      (d.company_public_data_screening_last_date && d.company_maturity_level) ||
      d.reporting_company_maturity_level_crc ||
      d.company_maturity_level_crc,
    maturity_level_self_reported:
      d.reporting_company_maturity_level_crc || d.company_maturity_level_crc,
    public_data_available: !!(
      d.reporting_company_has_public_data || d.company_has_public_data
    ),
    public_data_screening_status:
      d.reporting_company_public_data_screening_status ||
      d.company_public_data_screening_status,
    public_data_screening_last_date:
      d.reporting_company_public_data_screening_last_date ||
      d.company_public_data_screening_last_date,
    private_data_status:
      d.reporting_company_private_data_status || d.company_private_data_status,
    private_data_last_update:
      d.reporting_company_private_data_updation_last_date ||
      d.company_private_data_updation_last_date,
  }));
  return (
    <CollectionListDataGrid
      collectionId={collectionId}
      rows={rows}
      loading={!(data.length > 0) && hasCollections && isLoading}
    />
  );
}

export default UserCompanyCollectionList;
