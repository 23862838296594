import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

// import Box from 'components/common/Box';
import Typography from 'components/common/Typography';
import Alert from '@mui/material/Alert';

import Stack from 'components/common/Stack';

import Box from 'components/common/Box';

import colors from 'constants/colors';

function CollectionWidgetCard({
  title,
  titleBadge,
  caption,
  component,
}: {
  title?: string;
  titleBadge?: any;
  caption?: string;
  component?: any;
}) {
  return (
    <Box
      sx={{
        padding: 2,
        height: '160px',
        backgroundColor: colors.neutral100,
        borderRadius: '8px',
      }}
    >
      <Stack spacing={3} height={'100%'}>
        <Stack spacing={1} flex={1}>
          {title || titleBadge ? (
            <Stack spacing={2} direction={'row'}>
              {title && (
                <Box flex={1}>
                  <Typography variant={'h4'} sx={{ fontWeight: 100 }}>
                    {title}
                  </Typography>
                </Box>
              )}
              {titleBadge && (
                <Box flex={0} display={'flex'} alignItems={'center'}>
                  {titleBadge}
                </Box>
              )}
            </Stack>
          ) : (
            <Box height="42px" />
          )}

          {component && <Box>{component}</Box>}
        </Stack>
        {caption && (
          <Stack flex={0}>
            <Typography
              variant={'body1'}
              sx={{ fontWeight: 100, color: colors.neutral600 }}
            >
              {caption}
            </Typography>
          </Stack>
        )}
      </Stack>
    </Box>
  );
}

export default observer(CollectionWidgetCard);
