import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import Grid from '@mui/material/Grid';
import useAuth from 'hooks/useAuth';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import useUserCompany from 'hooks/useUserCompany';

// import Box from 'components/common/Box';
import Typography from 'components/common/Typography';
import Alert from '@mui/material/Alert';

import api from 'constants/api';

import ScheduleSendOutlinedIcon from '@mui/icons-material/ScheduleSendOutlined';

import { useState } from 'react';

import Button from 'components/common/Button';
import Check from 'components/icons/Check';

function UserDashboardCollectionsUpsell() {
  const auth = useAuth();

  const { t } = useTranslation('common');
  const { userCompany } = useUserCompany();

  const [notificationSending, setNotificaitonSending] = useState(false);
  const [notificationSent, setNotificaitonSent] = useState(false);
  const sendNotification = async () => {
    try {
      const formData = new FormData();
      formData.set('subject', '[Support] Info request: Collections');
      formData.set(
        'body',
        `
          user name: ${auth.userDetails.first_name} ${auth.userDetails.last_name}
          user email: ${auth.userDetails.email}
          user company: ${userCompany.name}
        `
      );
      formData.set('receiver_company', userCompany.name);
      setNotificaitonSending(true);
      const res = await axios.post(
        `${api.url}/emails/contact_tcc/`,
        formData,
        auth.requestConfig
      );
      if (res && res.data) {
        setNotificaitonSent(true);
      }
      setNotificaitonSending(false);
    } catch (err) {
      console.log(err);
      setNotificaitonSending(false);
    }
  };

  return (
    <Alert
      severity="warning"
      action={
        <Button
          color="warning"
          variant="outlined"
          size="small"
          disabled={notificationSending || notificationSent}
          onClick={() => {
            sendNotification();
          }}
          startIcon={
            notificationSent ? <Check /> : <ScheduleSendOutlinedIcon />
          }
        >
          {notificationSent ? t('Requested') : t('Request upgrade')}
        </Button>
      }
    >
      <Typography variant="body1" textAlign="left">
        {t(
          'You need to upgrade in order to access collections. Let us know if you are interested!'
        )}
      </Typography>
    </Alert>
  );
}

export default observer(UserDashboardCollectionsUpsell);
