import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import useAuth from 'hooks/useAuth';
import { useSearchParams } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

import UserDashboardSuppliersStore from './store/UserDashboardSuppliersStore';
import useUserCompany from 'hooks/useUserCompany';

import Typography from 'components/common/Typography';
import AppLoading from 'components/common/AppLoading';

import UserDashboardSuppliersTable from './UserDashboardSuppliersTable';
import UserDashboardSuppliersHeader from './UserDashboardSuppliersHeader';
import UserDashboardSuppliersSearch from './UserDashboardSuppliersSearch';
import DownloadAllScreeningData from './DownloadAllScreeningData';
import UserDashboardSuppliersAddSuppliers from './UserDashboardSuppliersAddSuppliers';
import UserDashboardMonSuppliersES from 'components/App/Routes/Dashboard/MySuppliers/UserDashboardMonSuppliersES';
import SuppliersInviteCard from 'components/common/SuppliersInviteCard';
import Alert from '@mui/material/Alert';
import Button from 'components/common/Button';
import ScheduleSendOutlinedIcon from '@mui/icons-material/ScheduleSendOutlined';
import axios from 'axios';
import api from 'constants/api';
import Check from 'components/icons/Check';

function UserDashboardSuppliersList() {
  const { t } = useTranslation('common');
  const auth = useAuth();
  let [searchParams] = useSearchParams();
  const {
    hasLoadedDashboardDataStatistics,
    loadDashboardData,
    loadUserCompanyStatistics,
    hasLoadedDashboardData,
    user_company_suppliers_count_on_search,
    user_company_suppliers_results,
    user_company_suppliers_with_valid_crc,
    supplier_invite_link,
  } = UserDashboardSuppliersStore();
  const { userCompany } = useUserCompany();

  const [notificationSending, setNotificaitonSending] = useState(false);
  const [notificationSent, setNotificaitonSent] = useState(false);
  const sendNotification = async () => {
    try {
      const formData = new FormData();
      formData.set(
        'subject',
        '[Support] Follow up request: Emission intensities'
      );
      formData.set(
        'body',
        `
          user name: ${auth.userDetails.first_name} ${auth.userDetails.last_name}
          user email: ${auth.userDetails.email}
        `
      );
      formData.set('receiver_company', userCompany.name);
      setNotificaitonSending(true);
      const res = await axios.post(
        `${api.url}/emails/contact_tcc/`,
        formData,
        auth.requestConfig
      );
      if (res && res.data) {
        setNotificaitonSent(true);
      }
      setNotificaitonSending(false);
    } catch (err) {
      console.log(err);
      setNotificaitonSending(false);
    }
  };

  let page = searchParams.get('page') || '1';
  let search = searchParams.get('search') || '';
  const rowsPerPage = 100;

  useEffect(() => {
    if (!hasLoadedDashboardDataStatistics) {
      loadUserCompanyStatistics({ auth });
    }
    if (hasLoadedDashboardDataStatistics) {
      loadDashboardData({ auth, page, search, rowsPerPage });
    }
  }, [
    auth,
    hasLoadedDashboardDataStatistics,
    loadDashboardData,
    loadUserCompanyStatistics,
    page,
    search,
  ]);
  return !hasLoadedDashboardData ? (
    <AppLoading />
  ) : userCompany.suppliers_count === 0 && hasLoadedDashboardData ? (
    <Stack spacing={{ xs: 2, sm: 3 }}>
      <Typography variant="h4" color="onSureface.100">
        {t('Supplier List')}
      </Typography>
      {userCompany.supplier_invite_link ? (
        <SuppliersInviteCard
          title={t('Invite Your Suppliers')}
          suppliersInvitationLink={supplier_invite_link}
        />
      ) : null}
      <UserDashboardMonSuppliersES
        id={(auth.userDetails as any).id}
        companyName={(auth.userDetails as any).company}
        userDetails={auth.userDetails}
        rowDirection={true}
      />
    </Stack>
  ) : (
    <Stack
      spacing={{ xs: 2, sm: 3 }}
      sx={{
        width: { xs: 'calc(100% - 65px)', lg: '100%' },
      }}
    >
      <UserDashboardSuppliersHeader
        totalSuppliersNumber={userCompany.suppliers_count}
        totalAssessedSupliers={user_company_suppliers_with_valid_crc}
      />
      <Box display="flex" flexDirection={{ xs: 'column-reverse', sm: 'row' }}>
        <Box flex={'1 1 auto'} pr={1} pt={{ xs: 2, sm: 0 }}>
          <UserDashboardSuppliersSearch />
        </Box>
        <Box flex="0 0 auto" textAlign={'right'} pr={1}>
          <DownloadAllScreeningData />
        </Box>
        <Box flex="0 0 auto" textAlign={'right'}>
          <UserDashboardSuppliersAddSuppliers
            id={(auth.userDetails as any).id}
            companyName={(auth.userDetails as any).company}
            userDetails={auth.userDetails}
          />
        </Box>
      </Box>
      <div
        style={{
          overflowX: 'auto',
        }}
      >
        <UserDashboardSuppliersTable
          slug={userCompany.slug}
          suppliers={user_company_suppliers_results}
          suppliersCount={user_company_suppliers_count_on_search}
          rowsPerPage={rowsPerPage}
        />
      </div>
      {userCompany.supplier_invite_link ? (
        <SuppliersInviteCard
          title={t('Invite Your Suppliers')}
          suppliersInvitationLink={supplier_invite_link}
        />
      ) : null}
    </Stack>
  );
}

export default observer(UserDashboardSuppliersList);
