// @ts-nocheck
import React, { useEffect, useState } from 'react';

import {
  ComposableMap,
  Geographies,
  Geography,
  Sphere,
  Graticule,
} from 'react-simple-maps';

import iso3To2 from './iso3To2';
import colors from 'constants/colors';
import useUserCompanyCollectionOverview from 'hooks/useUserCompanyCollectionOverview';
import { useParams } from 'react-router-dom';
import { getCountryName } from 'utils/getCountryName';
import axios from 'axios';

function WidgetCountries({ countries = [] }: { countries?: string[] }) {
  const [geoData, setGeoData] = useState(null);

  useEffect(() => {
    axios
      .get('/features.json')
      .then((response) => {
        setGeoData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching JSON:', error);
      });
  }, []);

  if (!geoData) return null;
  return (
    <ComposableMap
      projectionConfig={{
        rotate: [-10, 0, 0],
      }}
    >
      <Sphere stroke={colors.neutral200} strokeWidth={0.5} />
      <Graticule stroke={colors.neutral200} strokeWidth={0.5} />
      <Geographies geography={geoData}>
        {({ geographies }: any) =>
          geographies.map((geo: any) => {
            const yes = countries.includes(
              iso3To2[geo.id as keyof typeof iso3To2]
            );
            // if (yes) {
            //   console.log(geo.id);
            // }
            return (
              <Geography
                key={geo.rsmKey}
                geography={geo}
                fill={yes ? colors.primary300 : colors.neutral200}
              />
            );
          })
        }
      </Geographies>
    </ComposableMap>
  );
}

export default WidgetCountries;

type Entity = {
  org_data: OrgData;
  parent_relationships: OrganizationRelationship[]; // Relationships with parent entities
  child_relationships: OrganizationRelationship[]; // Relationships with child entities
  scope_1: Scope1;
  scope_2: Scope2;
  scope_3: Scope3;
  upstream_relationships: ValueChainRelationship[]; // Upstream value chain entities
  downstream_relationships: ValueChainRelationship[]; // Downstream value chain entities
  targets: EmissionTargets[]; // Emission reduction targets for the entity
  energy_usage: EnergyUsage[]; // Energy usage data for the entity
  net_zero_commitment?: NetZeroCommitment; // Details about the entity's net zero commitment
  products: Product[]; // Products offered by the entity
  certifications?: Certification[]; // Certifications held by the entity
  initiatives?: SustainabilityInitiative[]; // Sustainability initiatives undertaken by the entity
};

// Updated types for relational database normalization

type OrgData = {
  legal_name: string;
  brand_names?: string[];
  fy_start_end?: FiscalYear;
  revenue?: Revenue;
  employee_count?: number;
  sector: string;
  nace_code?: string;
  lei?: string;
  stockmarket_ticker?: string;
  legal_address: Address;
  hq_addresses?: Address[];
};

type FiscalYear = {
  start: string;
  end: string;
};

type Revenue = {
  amount: number;
  currency: string;
};

type Address = {
  address: string;
  country: string;
};

type DisclosureSource = {
  file_name: string;
  file_type: 'report' | 'certificate' | 'assessment' | 'other';
  verified_by?: string;
  verification_date?: string;
  description?: string;
  data_quality: 'primary' | 'secondary';
  data_attribution: 'direct' | 'estimated' | 'proxy';
  transparency: boolean;
};

type Scope1 = {
  emissions_sources: EmissionSource[];
  total_scope_1_emissions: TotalEmissions;
  emissions_methodology?: string;
};

type Scope2 = {
  emissions_sources: EmissionSource[];
  total_scope_2_emissions: TotalEmissions;
  emissions_methodology?: string;
};

type Scope3 = {
  categories: Scope3Category[];
  total_scope_3_emissions: TotalEmissions;
  emissions_methodology?: string;
};

type Scope3Category = {
  category_name: string;
  activity_data: EmissionSource[];
};

type EmissionSource = {
  source_name: string;
  emissions_type: 'CO2' | 'CH4' | 'N2O' | 'HFCs' | 'PFCs' | 'SF6' | 'NF3';
  fuel_type?: string;
  amount_consumed?: number;
  unit?: string;
  emissions_factor?: number;
  emissions_amount: number;
  disclosure_source?: DisclosureSource;
};

type TotalEmissions = {
  amount: number;
  reporting_year: string;
  disclosure_source?: DisclosureSource;
};

type ValueChainRelationship = {
  entity_id: string; // Reference to the related entity
  relationship_type: 'upstream' | 'downstream';
  description?: string;
  emissions_impact?: EmissionsImpact;
};

type OrganizationRelationship = {
  entity_id: string; // Reference to the related entity
  relationship_type: 'parent' | 'child';
  description?: string;
  disclosure_source?: DisclosureSource;
};

type EmissionTargets = {
  target_year: string;
  reduction_percentage: number;
  scope: 'scope_1' | 'scope_2' | 'scope_3' | 'all_scopes';
  description?: string;
  disclosure_source?: DisclosureSource;
};

type EnergyUsage = {
  energy_type: 'electricity' | 'natural_gas' | 'diesel' | 'renewable' | 'other';
  amount: number;
  unit: string;
  reporting_year: string;
  disclosure_source?: DisclosureSource;
};

type NetZeroCommitment = {
  target_year: string;
  scope_coverage: 'scope_1' | 'scope_2' | 'scope_3' | 'all_scopes';
  strategy_description?: string;
  disclosure_source?: DisclosureSource;
};

type Product = {
  product_name: string;
  category: string;
  emissions_profile?: ProductEmissionsProfile;
  lifecycle_assessment?: LifecycleAssessment;
  disclosure_source?: DisclosureSource;
};

type ProductEmissionsProfile = {
  scope_1_emissions?: number;
  scope_2_emissions?: number;
  scope_3_emissions?: number;
  total_emissions?: number;
  data_quality: 'primary' | 'secondary';
  data_attribution: 'direct' | 'estimated' | 'proxy';
  transparency: boolean;
  boundary: 'cradle_to_gate' | 'cradle_to_grave' | 'gate_to_gate'; // System boundary according to PACT
  emissions_factor_reference?: string; // Reference for the emissions factor used
};

type LifecycleAssessment = {
  phases: LifecyclePhase[];
  total_emissions: number;
  disclosure_source?: DisclosureSource;
};

type LifecyclePhase = {
  phase_name: string;
  emissions_amount: number;
  disclosure_source?: DisclosureSource;
};

type Certification = {
  certification_name: string;
  issuing_body: string;
  valid_until?: string;
  disclosure_source?: DisclosureSource;
};

type SustainabilityInitiative = {
  initiative_name: string;
  description?: string;
  start_date: string;
  end_date?: string;
  impact?: EmissionsImpact;
  disclosure_source?: DisclosureSource;
};

type EmissionsImpact = {
  scope_1_reduction?: number;
  scope_2_reduction?: number;
  scope_3_reduction?: number;
  disclosure_source?: DisclosureSource;
};
