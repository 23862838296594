const fakeData = [
  {
    company_uuid: Math.random(),
    company_name: 'Acme Corp',
    company_slug: 'test-company-214',
    company_sector: '1_11',
    company_country: 'DE',
    company_maturity_level_crc: null,
    company_maturity_level: 'FAIR',
    company_logo:
      'https://static.wikia.nocookie.net/fictionalcompanies/images/c/c2/ACME_Corporation.png',
    company_climate_score_overall: 6.1,
    company_has_public_data: false,
    company_public_data_screening_status: 'COMPLETED',
    company_public_data_screening_last_date: new Date(
      new Date().setDate(new Date().getDate() - 28)
    ),
    company_private_data_status: null,
    company_private_data_updation_last_date: null,
    reporting_company_name: null,
    reporting_company_slug: null,
    reporting_company_sector: '1_11',
    reporting_company_country: 'DE',
  },
  {
    company_uuid: Math.random(),
    company_name: 'Concrete Co.',
    company_slug: 'test-company-214',
    company_sector: '1_11',
    company_country: 'US',
    company_maturity_level_crc: 'FAIR',
    company_maturity_level: null,
    company_climate_score_overall: 3.9,
    company_public_data_screening_status: 'IN_PROGRESS',
    company_public_data_screening_last_date: null,
    company_private_data_status: 'ACCESS_GRANTED',
    company_private_data_updation_last_date: new Date(
      new Date().setDate(new Date().getDate() - 5)
    ),
    reporting_company_name: 'Concrete Group',
    reporting_company_slug: 'concrete-group',
    reporting_company_sector: '1_11',
    reporting_company_country: 'DE',
  },
  {
    company_uuid: Math.random(),
    company_name: 'RWS Web Services',
    company_slug: 'test-company-214',
    company_sector: '1_11',
    company_country: 'BR',
    company_maturity_level_crc: 'GOOD',
    company_maturity_level: 'FAIR',
    company_climate_score_overall: 10.4,
    company_has_public_data: false,
    company_public_data_screening_status: 'COMPLETED',
    company_public_data_screening_last_date: new Date(
      new Date().setDate(new Date().getDate() - 15)
    ),
    company_private_data_status: 'ACCESS_CAN_BE_REQUESTED',
    company_private_data_updation_last_date: new Date(
      new Date().setDate(new Date().getDate() - 105)
    ),
    reporting_company_name: 'Rainforest.com Inc',
    reporting_company_slug: 'rainforest-group',
    reporting_company_sector: '1_11',
    reporting_company_country: 'DE',
    reporting_company_has_public_data: true,
  },
  {
    company_uuid: Math.random(),
    company_name: 'Wisozk Ltd',
    company_slug: 'test-company-214',
    company_sector: '1_11',
    company_country: 'DE',
    company_maturity_level_crc: null,
    company_maturity_level: 'POOR',
    company_climate_score_overall: 0,
    company_public_data_screening_status: null,
    company_public_data_screening_last_date: null,
    company_private_data_status: null,
    company_private_data_updation_last_date: null,
    reporting_company_name: null,
    reporting_company_slug: null,
    reporting_company_sector: null,
    reporting_company_country: null,
  },
];

export default fakeData;
