import React, { useEffect, useState } from 'react';
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridPagination,
  GridRowParams,
  GridSortDirection,
  GridSortModel,
} from '@mui/x-data-grid';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import BusinessIcon from '@mui/icons-material/Business';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import { deDE } from '@mui/x-data-grid/locales';
import Chip from 'components/common/Chip';
import Container from 'components/common/Container';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import UpdateIcon from '@mui/icons-material/Update';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';

import Stack from 'components/common/Stack';
import ViewIcon from '@mui/icons-material/ArticleOutlined';
import useAdminCompanyJobs from 'hooks/useAdminCompanyJobs';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getLanguagePreference } from 'utils/browser';
import { getDurationBetweenDates, getLengthOfTime } from 'utils/date';
import Box from 'components/common/Box';
import colors from 'constants/colors';
import Card from 'components/common/Card';
import Button from 'components/common/Button';
import useUserCompanyCollectionOverview from 'hooks/useUserCompanyCollectionOverview';
import { getClimateScoreAsPercent } from 'utils/tools';
import Typography from 'components/common/Typography';
import { useAccessRequestCreate } from 'hooks/useAccessRequests';
import { LocationOnOutlined, Opacity, SendOutlined } from '@mui/icons-material';
import useUserCompany from 'hooks/useUserCompany';
import useSnackbarMessage from 'hooks/useSnackbarMessage';
import Check from 'components/icons/Check';
import Close from '@mui/icons-material/Close';
import Tooltip from 'components/common/Tooltip';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { Badge } from '@mui/material';
import useAuth from 'hooks/useAuth';
import axios from 'axios';
import api from 'constants/api';
import Loading from 'components/common/Loading';
import { fontSize } from 'constants/typography';
import Avatar from 'components/common/Avatar';
import { getCountryName } from 'utils/getCountryName';

interface ScoreProps {
  score?: number; // score is optional and ranges from 0 to 100
}

const ScoreComponent: React.FC<ScoreProps> = ({ score = 0 }) => {
  // Normalize score to fit within a scale of 0-10
  const normalizedScore = Math.round((score / 100) * 10);

  return (
    <Box display="flex" alignItems="center" height={'100%'}>
      {/* Display the numerical score */}
      <Typography variant="body1" sx={{ marginRight: 1, width: '24px' }}>
        {score}
      </Typography>

      {/* Display the score bar */}
      <Box display="flex">
        {[...Array(10)].map((_, index) => (
          <Box
            key={index}
            width={6}
            height={16}
            borderRadius={1}
            marginRight={'2px'}
            bgcolor={
              index < normalizedScore ? colors.secondary300 : colors.neutral200
            }
          />
        ))}
      </Box>
    </Box>
  );
};

function QualatativeDescripterChip({
  type,
}: {
  type?: 'VERY_GOOD' | 'GOOD' | 'FAIR' | 'POOR';
}) {
  const { t } = useTranslation();
  return type ? (
    <Chip
      label={
        type === 'GOOD' ? t('Good') : type === 'FAIR' ? t('Fair') : t('Poor')
      }
      style={{
        borderRadius: 4,
        fontSize: '12px',
        color: colors.neutral600,
        height: '24px',
        backgroundColor:
          type === 'GOOD'
            ? colors.primary100
            : type === 'FAIR'
            ? colors.warning100
            : colors.tertiary100,
      }}
    />
  ) : null;
}

function UserCompanyCollectionListDataGrid({
  rows = [],
  collectionId,
  hasCollections,
  loading,
}: {
  rows: any;
  collectionId?: string;
  hasCollections?: boolean;
  loading?: boolean;
}) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const snackbarMessage = useSnackbarMessage();
  const { userCompany } = useUserCompany();
  const [searchParams, setSearchParams] = useSearchParams();
  const auth = useAuth();

  const [notificationSending, setNotificaitonSending] = useState(false);
  const [notificationSent, setNotificaitonSent] = useState(false);
  const sendNotification = async (
    targetCompanyName: string,
    targetCompanyId: string
  ) => {
    try {
      const formData = new FormData();
      formData.set('subject', '[Support] Info request: Public screening');
      formData.set(
        'body',
        `
              user name: ${auth.userDetails.first_name} ${auth.userDetails.last_name}
              user email: ${auth.userDetails.email}
              user company: ${userCompany.name}

              target company: ${targetCompanyName}
              target company id: ${targetCompanyId}
            `
      );
      formData.set('receiver_company', userCompany.name);
      setNotificaitonSending(true);
      const res = await axios.post(
        `${api.url}/emails/contact_tcc/`,
        formData,
        auth.requestConfig
      );
      if (res && res.data) {
        setNotificaitonSent(true);
      }
      setNotificaitonSending(false);
    } catch (err) {
      console.log(err);
      setNotificaitonSending(false);
    }
  };

  const showFakeData = searchParams.get('example') === 'true';
  const { mutate: createRequest, isLoading: isLoadingCreate } =
    useAccessRequestCreate();

  const { data, isLoading, refetch, isRefetching } =
    useUserCompanyCollectionOverview({
      collection_id: collectionId,
    });

  const rowCount = rows.length;

  const columns: GridColDef[] = [
    {
      field: 'company_name',
      headerName: t('Name'),
      flex: 2,
      minWidth: 240,
      sortable: true,
      renderCell: (params) => (
        <Box
          display="flex"
          height="100%"
          alignItems="center"
          maxWidth="100%"
          sx={{
            minWidth: 0,
            overflow: 'hidden',
          }}
        >
          <Stack
            spacing={2}
            direction="row"
            alignItems="center"
            maxWidth="100%"
            sx={{
              minWidth: 0,
              overflow: 'hidden',
              flex: 1,
            }}
          >
            <Avatar
              sx={{
                width: '36px',
                height: '36px',
                color: colors.white,
                backgroundColor:
                  params.row.company_logo || params.row.reporting_company_logo
                    ? 'none'
                    : colors.neutral200,
                borderRadius: 1,
                '& img': {
                  objectFit: 'contain',
                },
              }}
              src={params.row.company_logo || params.row.reporting_company_logo}
              alt={params.row.company_name}
            >
              <BusinessIcon fontSize="small" />
            </Avatar>
            <Stack
              spacing={0}
              justifyContent="center"
              sx={{
                minWidth: 0,
                flex: 1,
                overflow: 'hidden',
              }}
            >
              <Stack spacing={1} direction={'row'} alignItems={'center'}>
                <Typography
                  noWrap
                  variant="body1"
                  title={params.row.company_name}
                  sx={{ cursor: 'default' }}
                >
                  {params.row.company_name}
                </Typography>
                {params.row.company_country ? (
                  <Typography
                    variant="body1"
                    color="inherit"
                    sx={{ opacity: 0.5, pl: 0.5, cursor: 'default' }}
                    title={getCountryName(params.row.company_country)}
                  >
                    {params.row.company_country}
                  </Typography>
                ) : null}
              </Stack>

              {params.row.reporting_company_name ? (
                <Box sx={{ minWidth: 0, overflow: 'hidden' }}>
                  <Stack
                    direction="row"
                    alignItems={'center'}
                    spacing={1}
                    sx={{
                      color: colors.onSurface200,
                      opacity: 0.6,
                      minWidth: 0,
                      overflow: 'hidden',
                      flex: 1,
                    }}
                  >
                    <FileCopyOutlinedIcon fontSize="inherit" color="inherit" />
                    <Typography
                      noWrap
                      variant="body2"
                      color="inherit"
                      sx={{ cursor: 'default' }}
                      title={params.row.reporting_company_name}
                    >
                      {params.row.reporting_company_name}
                    </Typography>
                    {params.row.reporting_company_country ? (
                      <Typography
                        variant="body2"
                        color="inherit"
                        sx={{ opacity: 0.8, pl: 0.5, cursor: 'default' }}
                        title={getCountryName(
                          params.row.reporting_company_country
                        )}
                      >
                        {params.row.reporting_company_country}
                      </Typography>
                    ) : null}
                  </Stack>
                </Box>
              ) : null}
            </Stack>
          </Stack>
        </Box>
      ),
    },
    {
      field: 'climate_score',
      headerName: t('ClimateChoice Score'),
      flex: 0,
      minWidth: 150,
      sortable: true,
      sortComparator: (v1, v2) => {
        // Return negative if v1 should come before v2.
        // Normally ascending would do v1 - v2, but we flip it:
        return v2 - v1;
      },
      renderCell: (params) => (
        <Box display={'flex'} height={'100%'} alignItems={'center'}>
          <ScoreComponent
            score={getClimateScoreAsPercent(params.row.climate_score)}
          />
        </Box>
      ),
    },
    {
      field: 'maturity_level',
      headerName: t('Maturity level'),
      flex: 0,
      minWidth: 120,
      sortable: true,
      sortComparator: (v1, v2, cellParams1, cellParams2) => {
        const order = ['VERY_GOOD', 'GOOD', 'FAIR', 'POOR'];
        // Determine the indices of v1 and v2 in the predefined order
        const i1 = order.indexOf(v1);
        const i2 = order.indexOf(v2);

        // If a value isn't found, you may decide how to handle it.
        // For now, let's place unfound values at the end.
        const safeI1 = i1 === -1 ? order.length : i1;
        const safeI2 = i2 === -1 ? order.length : i2;

        return safeI1 - safeI2;
      },
      renderCell: (params) => {
        return (
          <Box display={'flex'} height={'100%'} alignItems={'center'}>
            {params.row.maturity_level_self_reported ? (
              <Tooltip
                title={
                  <Stack spacing={2}>
                    <Stack spacing={1} direction={'row'} alignItems={'center'}>
                      <InfoOutlined
                        sx={{ transform: 'scale(1.2)', fontSize: '15px' }}
                      />
                      <Box>{t('Self reported data exists.')}</Box>
                    </Stack>
                    <Stack spacing={1} direction={'row'} alignItems={'center'}>
                      <Box>{t('Self reported maturity') + ':'}</Box>
                      <Box>
                        <QualatativeDescripterChip
                          type={params.row.maturity_level_self_reported}
                        />
                      </Box>
                    </Stack>
                  </Stack>
                }
              >
                <Badge
                  sx={{
                    '& .MuiBadge-badge': {
                      backgroundColor: colors.white,
                      padding: 0,
                      width: '15px',
                      minWidth: '15px',
                      height: '15px',
                      minHeight: '15px',
                    },
                  }}
                  badgeContent={
                    <InfoOutlined
                      sx={{
                        transform: 'scale(1.2)',
                        fontSize: '15px',
                        color: colors.neutral400,
                      }}
                    />
                  }
                >
                  <QualatativeDescripterChip type={params.row.maturity_level} />
                </Badge>
              </Tooltip>
            ) : (
              <QualatativeDescripterChip type={params.row.maturity_level} />
            )}
          </Box>
        );
      },
    },
    {
      field: 'public_data_available',
      headerName: t('Public data'),
      flex: 1,
      minWidth: 150,
      sortable: false,
      renderCell: (params) => {
        const longAgo = getDurationBetweenDates(
          params.row.public_data_screening_last_date,
          new Date().toUTCString()
        );
        return (
          <Box
            display={'flex'}
            alignItems={'center'}
            height={'100%'}
            sx={{ maxWidth: '100%' }}
          >
            <Stack
              direction={'row'}
              spacing={1}
              alignItems={'center'}
              sx={{ maxWidth: '100%' }}
            >
              {params.row.public_data_available ? (
                <Box
                  sx={{
                    display: 'flex',
                    backgroundColor: colors.primary100,
                    height: '30px',
                    pl: 1.5,
                    pr: 1.5,
                    alignItems: 'center',
                  }}
                >
                  <Check color="primary" fontSize="small" />
                </Box>
              ) : !params.row.public_data_available &&
                params.row.public_data_screening_last_date ? (
                <Box
                  sx={{
                    display: 'flex',
                    backgroundColor: colors.neutral100,
                    height: '30px',
                    pl: 1.5,
                    pr: 1.5,
                    alignItems: 'center',
                  }}
                >
                  <Close sx={{ color: colors.neutral400 }} fontSize="small" />
                </Box>
              ) : params.row.public_data_screening_status === 'IN_PROGRESS' ? (
                <Box>
                  <Tooltip
                    title={
                      <Stack spacing={2}>
                        <Stack
                          spacing={1}
                          direction={'row'}
                          alignItems={'center'}
                        >
                          <Box
                            sx={{
                              width: 10,
                              height: 10,
                              borderRadius: '50%',
                              backgroundColor: colors.warning500,
                            }}
                          />
                          <Box>{t('Screening in progress')}</Box>
                        </Stack>
                      </Stack>
                    }
                  >
                    <Badge
                      sx={{
                        '& .MuiBadge-badge': {
                          backgroundColor: colors.white,
                          padding: 0,
                          width: '15px',
                          minWidth: '15px',
                          height: '15px',
                          minHeight: '15px',
                        },
                      }}
                      badgeContent={
                        <InfoOutlined
                          sx={{
                            transform: 'scale(1.2)',
                            fontSize: '15px',
                            color: colors.neutral400,
                          }}
                        />
                      }
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          backgroundColor: colors.neutral100,
                          height: '30px',
                          pl: 1.5,
                          pr: 1.5,
                          alignItems: 'center',
                        }}
                      >
                        <Loading
                          disableShrink
                          size="18px"
                          sx={{ color: colors.neutral400 }}
                        />
                      </Box>
                    </Badge>
                  </Tooltip>
                </Box>
              ) : (
                <Button
                  variant="text"
                  disabled={
                    showFakeData || notificationSending || notificationSent
                  }
                  onClick={() => {
                    sendNotification(
                      params.row.company_name,
                      params.row.company_uuid
                    );
                  }}
                  sx={{
                    whiteSpace: 'nowrap',
                    alignSelf: 'center', // Ensures the button aligns in the center
                  }}
                  startIcon={notificationSent ? <Check /> : <AutoFixHighIcon />}
                >
                  {notificationSent ? t('Requested') : t('Request')}
                </Button>
              )}

              {longAgo ? (
                <Stack
                  direction={'row'}
                  spacing={0.5}
                  alignItems={'center'}
                  sx={{ opacity: 0.6, overflow: 'hidden' }}
                >
                  <CachedOutlinedIcon fontSize="small" color="inherit" />

                  <Typography variant="body2" noWrap>
                    {longAgo}
                  </Typography>
                </Stack>
              ) : null}
            </Stack>
          </Box>
        );
      },
    },
    // need to figure out how to enable these via the datagrid config - this one should be disabled by default, but allowed so we can sort by it.
    // {
    //   field: 'public_data_screening_last_date',
    //   headerName: t('Last public update'),
    //   flex: 0,
    //   minWidth: 150,
    //   sortable: true,
    //   renderCell: (params) => {
    //     const longAgo = getDurationBetweenDates(
    //       params.row.public_data_screening_last_date,
    //       new Date().toUTCString()
    //     );
    //     return (
    //       <Box
    //         whiteSpace={'nowrap'}
    //         overflow={'hidden'}
    //         textOverflow={'ellipsis'}
    //         sx={{
    //           color: longAgo ? undefined : colors.neutral500,
    //         }}
    //         title={longAgo}
    //       >
    //         {longAgo || t('Never')}
    //       </Box>
    //     );
    //   },
    // },
    {
      field: 'private_data_status',
      headerName: t('Private data'),
      flex: 1,
      minWidth: 150,
      sortable: false,
      renderCell: (params) => {
        const longAgo = getDurationBetweenDates(
          params.row.private_data_last_update,
          new Date().toUTCString()
        );
        return (
          <Stack
            spacing={1}
            direction={'row'}
            alignItems={'center'}
            height={'100%'}
            sx={{ maxWidth: '100%' }}
          >
            {params.row.private_data_status === 'ACCESS_CAN_BE_REQUESTED' ? (
              <>
                <Button
                  startIcon={<SendOutlined />}
                  disabled={
                    showFakeData || isLoadingCreate || isLoading || isRefetching
                  }
                  onClick={() => {
                    if (
                      userCompany?.id &&
                      params.row?.id &&
                      confirm(
                        t(
                          'Requesting access will notify a representitive at {{target_company}}. Do you wish to continue?',
                          { target_company: params.row.company_name }
                        )
                      )
                    ) {
                      createRequest({
                        data: {
                          data_receiver: userCompany?.id,
                          data_provider: params.row.id,
                        },
                        onSuccess: () => {
                          refetch();
                          snackbarMessage.addMessage({
                            message: t('Request sent'),
                          });
                        },
                      });
                    }
                  }}
                >
                  {t('Request')}
                </Button>
              </>
            ) : params.row.private_data_status === 'ACCESS_GRANTED' ? (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    backgroundColor: colors.primary100,
                    height: '30px',
                    pl: 1.5,
                    pr: 1.5,
                    alignItems: 'center',
                  }}
                >
                  <Check fontSize="small" sx={{ color: colors.primary500 }} />
                </Box>
                {longAgo ? (
                  <Stack
                    direction={'row'}
                    spacing={0.5}
                    alignItems={'center'}
                    sx={{ opacity: 0.6, overflow: 'hidden' }}
                  >
                    <CachedOutlinedIcon fontSize="small" color="inherit" />

                    <Typography variant="body2" noWrap>
                      {longAgo}
                    </Typography>
                  </Stack>
                ) : null}
              </>
            ) : params.row.private_data_status === 'ACCESS_REQUESTED' ? (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    backgroundColor: colors.neutral100,
                    height: '30px',
                    pl: 1.5,
                    pr: 1.5,
                    alignItems: 'center',
                  }}
                >
                  <Loading
                    disableShrink
                    size="18px"
                    sx={{ color: colors.neutral400 }}
                  />
                </Box>
              </>
            ) : null}
          </Stack>
        );
      },
    },
    // {
    //   field: 'private_data_last_update',
    //   headerName: t('Last private update'),
    //   flex: 0,
    //   minWidth: 150,
    //   sortable: true,
    //   renderCell: (params) => {
    //     const longAgo = getDurationBetweenDates(
    //       params.row.private_data_last_update,
    //       new Date().toUTCString()
    //     );
    //     return (
    //       <Box
    //         whiteSpace={'nowrap'}
    //         overflow={'hidden'}
    //         textOverflow={'ellipsis'}
    //         sx={{
    //           color: longAgo ? undefined : colors.neutral500,
    //         }}
    //         title={longAgo}
    //       >
    //         {longAgo || t('Never')}
    //       </Box>
    //     );
    //   },
    // },
    {
      field: 'actions',
      type: 'actions',
      width: 40,
      getActions: (params: GridRowParams) => [
        ...(!!params.row.reporting_company_slug
          ? [
              <GridActionsCellItem
                disabled={showFakeData}
                icon={<OpenInNewIcon />}
                onClick={() =>
                  navigate(`/company/${params.row.reporting_company_slug}`)
                }
                label={t('View reporting company profile')}
                showInMenu
              />,
            ]
          : []),
        <GridActionsCellItem
          disabled={showFakeData}
          icon={<OpenInNewIcon />}
          onClick={() => navigate(`/company/${params.row.company_slug}`)}
          label={t('View company profile')}
          showInMenu
        />,
      ],
    },
  ];
  const localeText = {
    ...(i18n.language === 'de'
      ? deDE.components.MuiDataGrid.defaultProps.localeText
      : {}),
    noRowsLabel: t('This collection is empty.'),
  };

  // Parse initial sorting state from URL
  const initialSortField = searchParams.get('sortField') || 'company_name';
  const initialSortDirection =
    (searchParams.get('sortDirection') as GridSortDirection) || 'asc';

  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: initialSortField, sort: initialSortDirection },
  ]);

  // Handle user-triggered sorting changes
  const handleSortModelChange = (newModel: GridSortModel) => {
    if (newModel.length > 0) {
      const { field, sort } = newModel[0];

      // Update the sortModel state
      setSortModel(newModel);

      // Update the URL search params
      const newParams: Record<string, string> = { sortField: field };
      if (sort) {
        newParams.sortDirection = sort;
      }
      setSearchParams(
        {
          ...Object.fromEntries(searchParams.entries()), // Preserve existing params
          ...newParams,
        },
        { replace: true }
      );
    }
  };

  return (
    <Box minHeight="400px">
      <DataGrid
        loading={loading}
        localeText={localeText}
        rows={rows}
        columns={columns}
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 100, page: 0 }, // Ensures the default page size is 100
          },
        }}
        rowSelection={false}
        rowHeight={70}
        pageSizeOptions={[100]}
        sx={{ minHeight: hasCollections ? undefined : 400 }}
      />
    </Box>
  );
}

export default UserCompanyCollectionListDataGrid;
