import React, { useEffect, useState } from 'react';
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridPagination,
  GridRowParams,
  GridSortDirection,
  GridSortModel,
} from '@mui/x-data-grid';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import BusinessIcon from '@mui/icons-material/Business';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import { deDE } from '@mui/x-data-grid/locales';
import Chip from 'components/common/Chip';
import Container from 'components/common/Container';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import UpdateIcon from '@mui/icons-material/Update';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';

import Stack from 'components/common/Stack';
import ViewIcon from '@mui/icons-material/ArticleOutlined';
import useAdminCompanyJobs from 'hooks/useAdminCompanyJobs';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getLanguagePreference } from 'utils/browser';
import { getDurationBetweenDates, getLengthOfTime } from 'utils/date';
import Box from 'components/common/Box';
import colors from 'constants/colors';
import Card from 'components/common/Card';
import Button from 'components/common/Button';
import useUserCompanyCollectionOverview from 'hooks/useUserCompanyCollectionOverview';
import { getClimateScoreAsPercent } from 'utils/tools';
import Typography from 'components/common/Typography';
import { useAccessRequestCreate } from 'hooks/useAccessRequests';
import { LocationOnOutlined, Opacity, SendOutlined } from '@mui/icons-material';
import useUserCompany from 'hooks/useUserCompany';
import useSnackbarMessage from 'hooks/useSnackbarMessage';
import Check from 'components/icons/Check';
import Close from '@mui/icons-material/Close';
import Tooltip from 'components/common/Tooltip';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { Badge } from '@mui/material';
import useAuth from 'hooks/useAuth';
import axios from 'axios';
import api from 'constants/api';
import Loading from 'components/common/Loading';
import { fontSize } from 'constants/typography';
import Avatar from 'components/common/Avatar';
import { getCountryName } from 'utils/getCountryName';

function CustomNoRowsOverlay() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent={'center'}
      height={'100%'}
      spacing={2}
    >
      <Box>
        {t(
          'Collections are groups of companies, suppliers, or other partners.'
        )}
      </Box>
      <Button
        startIcon={<ArrowDownwardIcon />}
        variant="contained"
        color="primary"
        onClick={() => navigate('/dashboard/my-company/collections/example')}
      >
        {t('Show Example Collection')}
      </Button>
    </Stack>
  );
}

function UserCompanyCollectionListDataGrid({
  rows = [],
  loading,
}: {
  rows: any;
  loading?: boolean;
}) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const snackbarMessage = useSnackbarMessage();
  const { userCompany } = useUserCompany();
  const [searchParams, setSearchParams] = useSearchParams();
  const auth = useAuth();

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('Name'),
      flex: 2,
      minWidth: 240,
      sortable: true,
      renderCell: (params) => (
        <Box
          display="flex"
          height="100%"
          alignItems="center"
          maxWidth="100%"
          sx={{
            minWidth: 0,
            overflow: 'hidden',
          }}
        >
          <Stack
            spacing={2}
            direction="row"
            alignItems="center"
            maxWidth="100%"
            sx={{
              minWidth: 0,
              overflow: 'hidden',
              flex: 1,
            }}
          >
            <Avatar
              sx={{
                width: '36px',
                height: '36px',
                color: colors.white,
                backgroundColor: params.row.image ? 'none' : colors.neutral200,
                borderRadius: 1,
                '& img': {
                  objectFit: 'contain',
                },
              }}
              src={params.row.image}
              alt={params.row.name}
            >
              <ListAltIcon />
            </Avatar>
            <Stack
              spacing={0}
              justifyContent="center"
              sx={{
                minWidth: 0,
                flex: 1,
                overflow: 'hidden',
              }}
            >
              <Stack spacing={1} direction={'row'} alignItems={'center'}>
                <Typography
                  noWrap
                  variant="body1"
                  title={params.row.name}
                  sx={{ cursor: 'default' }}
                >
                  {params.row.name}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      ),
    },

    {
      field: 'actions',
      type: 'actions',
      width: 40,
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          icon={<OpenInNewIcon />}
          onClick={() =>
            navigate(`/dashboard/my-company/collections/${params.row.id}`)
          }
          label={t('View collection')}
          showInMenu
        />,
      ],
    },
  ];
  const localeText = {
    ...(i18n.language === 'de'
      ? deDE.components.MuiDataGrid.defaultProps.localeText
      : {}),
    noRowsLabel: t('No collections.'),
  };

  return (
    <Box minHeight="400px">
      <DataGrid
        loading={loading}
        localeText={localeText}
        rows={rows}
        onRowClick={(params) =>
          navigate(`/dashboard/my-company/collections/${params.row.id}`)
        }
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 100, page: 0 }, // Ensures the default page size is 100
          },
        }}
        rowSelection={false}
        rowHeight={70}
        pageSizeOptions={[100]}
        sx={{ minHeight: 400 }}
        slots={{
          noRowsOverlay:
            !loading && rows.length === 0 ? CustomNoRowsOverlay : undefined,
        }}
      />
    </Box>
  );
}

export default UserCompanyCollectionListDataGrid;
