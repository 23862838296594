import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import useUserCompanyCollectionOverview from 'hooks/useUserCompanyCollectionOverview';

import useUserCompany from 'hooks/useUserCompany';
import useSnackbarMessage from 'hooks/useSnackbarMessage';

import CollectionsListDataGrid from './CollectionsListDataGrid';
import useUserCompanyCollections from 'hooks/useUserCompanyCollections';
import Stack from 'components/common/Stack';
import CollectionsUpsell from './CollectionsUpsell';

function UserCompanyCollectionsList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const snackbarMessage = useSnackbarMessage();
  const { userCompany } = useUserCompany();
  const [searchParams] = useSearchParams();

  const { isLoading, data } = useUserCompanyCollections();

  const rows =
    data?.length > 0
      ? data.map((d: any) => ({
          id: d.id,
          name: d.name,
        }))
      : [];
  return (
    <Stack spacing={2}>
      <CollectionsListDataGrid rows={rows} loading={isLoading} />
      {!isLoading && data?.length === 0 ? <CollectionsUpsell /> : null}
    </Stack>
  );
}

export default UserCompanyCollectionsList;
