import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import Box from 'components/common/Box';
import Typography from '@mui/material/Typography';
import ConfidentialDataAccessTable from './ConfidentialDataAccessTable';
import Stack from 'components/common/Stack';

const UserCompanyVisibilityControls = () => {
  const { t } = useTranslation('common');

  return (
    <Stack spacing={2}>
      <Typography variant="h5">{t('Data Access List')}</Typography>
      <Box>
        <Typography
          variant={'caption2'}
          color={'neutral.contrastText'}
          mb={'10px'}
        >
          {t(
            `This setting controls who is able to access the data you have provided on this platform. Here you see exactly which companies can see your confidential data and which not. In addition, you can choose to share your confidential data with other companies that requested data access from your company.`
          )}
        </Typography>
      </Box>
      <ConfidentialDataAccessTable />
    </Stack>
  );
};

export default observer(UserCompanyVisibilityControls);
