import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { scrollToTop } from 'utils/browser';
import useAuth from 'hooks/useAuth';
import useSnackbarMessage from 'hooks/useSnackbarMessage';
import useSignUp from 'hooks/useSignUp';

import Grid from 'components/common/Grid';
import Box from 'components/common/Box';
import Stack from 'components/common/Stack';
import TccInputTextField from 'components/common/TccInputTextField';
import Button from 'components/common/Button/Button';
import { RadioGroup, Radio, FormLabel, FormControlLabel } from '@mui/material';

import SelectMenu from 'components/common/SelectMenu';
import Form from 'components/common/Form';
import TccCheckbox from 'components/common/TccCheckbox';
import Typography from 'components/common/Typography';
import TextWithLinks from 'components/common/TextWithLinks';
import Container from 'components/common/Container';
import LinearStepper from 'components/common/LinearStepper';
import CurrencySelect from 'components/common/CurrencySelect';

import SignUpHeader from './SignUpHeader';
import SectorSelect from 'components/common/SectorSelect';

const SignUpForm2 = () => {
  const { t } = useTranslation('common');
  const auth = useAuth();
  const snackbarMessage = useSnackbarMessage();
  const { setFormPage, signUpForm2, submitSignUp, isSubmitting, hasSubmitted } =
    useSignUp();

  const currentYear = new Date().getFullYear();
  const reportingYears = Array.from({ length: 11 }, (x, i) => currentYear - i);

  const str2bool = (value: string) => {
    if (value && typeof value === 'string') {
      if (value.toLowerCase() === 'yes') return true;
      if (value.toLowerCase() === 'no') return false;
    }
    return value;
  };
  const bool2str = (value: boolean | undefined) => {
    if (value !== undefined && typeof value === 'boolean') {
      if (value === true) return 'yes';
      if (value === false) return 'no';
    }
    return value;
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <Container pageContainer maxWidth="md">
      <LinearStepper
        steps={[t('Registration Sign up'), t('Climate Readiness Check')]}
        activeStep={0}
        maxWidth={400}
      />
      <Form onSubmit={() => submitSignUp({ auth, snackbarMessage })}>
        <Grid container spacing={3} p={4}>
          <Grid item xs={12}>
            <SignUpHeader confidential />
          </Grid>
          <Grid item>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              sx={{ width: 400 }}
              onChange={(e) =>
                signUpForm2.setValue(
                  'has_subsidiaries',
                  str2bool(e.target.value)
                )
              }
              value={bool2str(signUpForm2.values.get('has_subsidiaries')) || ''}
            >
              <FormLabel id="demo-radio-buttons-group-label">
                <Typography variant={'body1'} color={'neutral.contrastText'}>
                  {t(
                    'Does the company you are registering have any subsidiaries?*'
                  )}
                </Typography>
              </FormLabel>
              <Stack direction={'row'}>
                <FormControlLabel
                  value="yes"
                  control={<Radio required />}
                  label={t('Yes')}
                  sx={{
                    '.MuiFormControlLabel-asterisk': {
                      display: 'none',
                    },
                  }}
                />
                <FormControlLabel
                  value="no"
                  control={<Radio required />}
                  label={t('No')}
                  sx={{
                    '.MuiFormControlLabel-asterisk': {
                      display: 'none',
                    },
                  }}
                />
              </Stack>
            </RadioGroup>
          </Grid>
          <Grid item xs={12}>
            <TccInputTextField
              title={t(`Parent Company Name`)}
              subTitle={t('Legal name of parent company (if applicable).')}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                signUpForm2.setValue('parent_company_name', e.target.value);
              }}
              value={signUpForm2.values.get('parent_company_name')}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <SectorSelect
              required
              title={t('Sector')}
              subtitle={t(
                'Please select the sector that represents the majority of your business activities'
              )}
              error={signUpForm2.errors.has('sector')}
              value={signUpForm2.values.get('sector')}
              onChange={(sector) => signUpForm2.setValue('sector', sector)}
            />
          </Grid>
          <Grid item xs={12}>
            <TccInputTextField
              title={t(`Company LEI code`)}
              subTitle={t(
                'Please enter the Legal Entity Identifier (LEI) code for your company (optional).'
              )}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                signUpForm2.setValue('lei_code', e.target.value);
              }}
              value={signUpForm2.values.get('lei_code')}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant="body1" color="neutral.contrastText">
              {t(
                'Total number of employees and reporting year for the company you are registering *'
              )}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TccInputTextField
                  required
                  subTitle={t(`Amount`)}
                  type="number"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    signUpForm2.setValue('employee_count', e.target.value);
                  }}
                  value={signUpForm2.values.get('employee_count')}
                  onBlur={() => signUpForm2.checkFieldError('employee_count')}
                  error={signUpForm2.errors.has('employee_count')}
                  helpText={signUpForm2.errors.get('employee_count')}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <SelectMenu
                  required
                  items={reportingYears}
                  subtitle={t('Year')}
                  error={signUpForm2.errors.has('employee_count_year')}
                  handleChange={(e) => {
                    signUpForm2.setValue('employee_count_year', e.target.value);
                  }}
                  value={signUpForm2.values.get('employee_count_year')}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Typography variant="body1" color="neutral.contrastText">
              {t(
                'Total global revenue, its currency and reporting year of the company you are registering'
              )}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TccInputTextField
                  subTitle={t('Global revenue')}
                  type="number"
                  value={signUpForm2.values.get('turnover_amount')}
                  onBlur={() => signUpForm2.checkFieldError('turnover_amount')}
                  error={signUpForm2.errors.has('turnover_amount')}
                  helpText={signUpForm2.errors.get('turnover_amount')}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    signUpForm2.setValue('turnover_amount', e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box>
                  <Typography
                    variant={'caption2'}
                    color={'neutral.contrastText'}
                    mb={1}
                    display="block"
                  >
                    {t('Currency of revenue')}
                  </Typography>
                </Box>
                <CurrencySelect
                  onChange={(currency) => {
                    signUpForm2.setValue('currency', currency);
                  }}
                  value={signUpForm2.values.get('currency')}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <SelectMenu
                  items={reportingYears}
                  subtitle={t('Reporting year of revenue')}
                  error={signUpForm2.errors.has('turnover_year')}
                  handleChange={(e) => {
                    signUpForm2.setValue('turnover_year', e.target.value);
                  }}
                  value={signUpForm2.values.get('turnover_year')}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item mt={4} xs={12}>
            <TccInputTextField
              required
              title={t(`Create a password`)}
              subTitle={t(
                `Your password must contain at least 10 characters, consisting of both letters and numbers. It should also not contain your name, email or other identifiable details.`
              )}
              placeholder={`${t('New password')}`}
              type="password"
              value={signUpForm2.values.get('password')}
              error={
                signUpForm2.errors.has('password_confirm') ||
                signUpForm2.errors.has('password')
              }
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                signUpForm2.setValue('password', e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} mt={-1}>
            <TccInputTextField
              required
              type="password"
              placeholder={`${t('Repeat password')}`}
              value={signUpForm2.values.get('password_confirm')}
              onBlur={() => signUpForm2.checkFieldError('password_confirm')}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                signUpForm2.setValue('password_confirm', e.target.value);
              }}
            />
          </Grid>
          <Grid item mt={4}>
            <Stack gap={2}>
              <Stack>
                <Typography variant={'h4'} color={'black'} mb={'10px'}>
                  {t(`Terms and Conditions`)}
                </Typography>
                <>
                  <TextWithLinks
                    blankPage
                    initialText={t(
                      `The use of our platform is governed by our {{link_start}}Terms and Conditions{{link_end}}.`
                    )}
                    links={[
                      t('https://theclimatechoice.com/legal/terms-of-use/'),
                    ]}
                  />
                  <TextWithLinks
                    blankPage
                    initialText={t(
                      `Details on how your data is processed in relation to the use of our platform can be found in our {{link_start}}Privacy Policy{{link_end}}.`
                    )}
                    links={[
                      t('https://theclimatechoice.com/legal/privacy-policy/'),
                    ]}
                  />
                </>
              </Stack>

              <Stack direction="row" alignItems="center">
                <TccCheckbox
                  required
                  onChange={
                    (
                      e: React.ChangeEvent<HTMLInputElement>,
                      checked: boolean
                    ) => signUpForm2.setValue('accepted_terms', checked)
                    // setPrivacyPolicy(e)
                  }
                  sx={{ pl: '0px' }}
                  checked={
                    signUpForm2.values.get('accepted_terms') === true
                      ? true
                      : false
                  }
                />
                <Typography variant="body1" color={'inherit'}>
                  {t('I have read and consent to the Terms and Conditions*')}
                </Typography>
              </Stack>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1" color={'neutral.contrastText'}>
              {t(
                `After submitting your details we will send you an email to verify your email address. Please follow the instructions to complete the verification. We will also use your email address to send you information about similar goods and services to support your company's climate transformation from time to time. You can object to this informational use (e.g. by clicking the unsubscribe button within the email) at any time.`
              )}
            </Typography>
          </Grid>
          {signUpForm2.hasErrors && (
            <Grid item mt={2}>
              <Typography color="error">
                {Array.from(signUpForm2.errors.values()).join(' ')}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              type="submit"
              size="medium"
              disabled={isSubmitting || hasSubmitted || signUpForm2.hasErrors}
            >
              {t('Submit details')}
            </Button>
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={4}>
            <Button
              fullWidth
              sx={{ height: '40px' }}
              variant="formBackButton"
              type="button"
              size="small"
              onClick={() => setFormPage(1)}
            >
              {t('back')}
            </Button>
          </Grid>
          <Grid item xs={4} />
        </Grid>
      </Form>
    </Container>
  );
};

export default observer(SignUpForm2);
