import React, { useState, useLayoutEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
  GridRowSelectionModel,
} from '@mui/x-data-grid';
import { dateReadable } from 'utils/date';
import TccTooltipText from 'components/common/TccTooltipText';
import { useMutation } from '@tanstack/react-query';
import UserDashboardSuppliersStore from '../store/UserDashboardSuppliersStore';

import * as schema from 'types/backendSchema';

import Typography from 'components/common/Typography';

// import SelectCard from './SelectCard';
import CustomTableFooter from './CustomTableFooter/CustomTableFooter';
import ColHeader from './UserDashboardSuppliersTableComps/ColHeader';
import CompanyTitleCell from './UserDashboardSuppliersTableComps/CompanyTitleCell';

import { styled } from '@mui/system';
import colors from 'constants/colors';
import Box from 'components/common/Box';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import IconButton from 'components/common/IconButton';
import api from 'constants/api';
import axios from 'axios';
import useAuth from 'hooks/useAuth';
import { getClimateScoreAsPercent } from 'utils/tools';
import { Send as SendIcon, SendOutlined } from '@mui/icons-material';
import Button from 'components/common/Button';
import Stack from 'components/common/Stack';

import useDataAccessList, {
  useAccessRequestCreate,
} from 'hooks/useAccessRequests';
import useUserCompany from 'hooks/useUserCompany';
import useSnackbarMessage from 'hooks/useSnackbarMessage';

const StyledDataGrid = styled(DataGrid)(`
.MuiDataGrid-row:nth-of-type(odd){
    background: white;
  }
  .MuiDataGrid-row:hover{
    background: ${colors.primary100};
  }
.MuiDataGrid-columnHeaders{
  background: ${colors.neutral200};
}
.MuiDataGrid-columnHeaderTitle{
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}
.MuiDataGrid-rowSeparator{
  display: 'none',
},
`);

type Props = {
  slug: string | undefined;
  suppliers: schema.operations['user_company_suppliers_list']['responses'][200]['schema']['results'];
  suppliersCount: number | null;
  rowsPerPage: number;
};

export default function UserDashboardSuppliersTable({
  slug,
  suppliers,
  suppliersCount,
  rowsPerPage,
}: Props) {
  const { t } = useTranslation('common');
  const auth = useAuth();
  const [searchParams] = useSearchParams();
  const { loadDashboardData } = UserDashboardSuppliersStore();
  let page = searchParams.get('page') || '1';
  let search = searchParams.get('search') || '';
  const { userCompany } = useUserCompany();

  const { mutate } = useMutation({
    onSuccess: (res) => {
      loadDashboardData({ auth, page, search, rowsPerPage });
    },
    onError: (err) => {
      console.log(err);
    },
    mutationFn: async (id: string) => {
      const res = await axios.delete(
        `${api.url}/user/company/suppliers/relations/${id}`,
        auth.requestConfig
      );
      return res;
    },
  });

  // const [comapniesSelectedLength, setComapniesSelectedLength] = useState(0);
  const [selectionModel, setSelectionModel] =
    React.useState<GridRowSelectionModel>([]);

  // const [selectedCompaniesSlug, setSelectedCompaniesSlug] =
  //   React.useState<GridSelectionModel>([]);

  const {
    data: requests,
    refetch: refetchRequests,
    isLoading: isLoadingRequests,
  } = useDataAccessList();
  const { mutate: createRequest, isLoading: isLoadingCreate } =
    useAccessRequestCreate();

  const snackbarMessage = useSnackbarMessage();

  const [height, setHeight] = useState(340);

  useLayoutEffect(() => {
    setHeight(suppliers.length * 52 + 125);
  }, [suppliers.length]);

  const ClearSelection = () => {
    setSelectionModel([]);
    // setComapniesSelectedLength(0);
  };

  const columns: GridColDef[] = [
    {
      field: 'supplierName',
      headerName: `${t('Company Name')}`,
      width: 270,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => <CompanyTitleCell params={params} />,
      renderHeader: (params) => {
        return <ColHeader title={params.colDef.headerName} />;
      },
    },
    {
      field: 'supplierReportingCompanyName',
      headerName: `${t('Reporting Company')}`,
      width: 240,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) =>
        params.row.supplierReportingCompanySlug ? (
          <Link
            to={`/company/${params.row.supplierReportingCompanySlug}`}
            style={{ textDecoration: 'none', color: colors.neutral500 }}
          >
            <TccTooltipText text={params.row.supplierReportingCompanyName} />
          </Link>
        ) : (
          params.row.supplierReportingCompanyName
        ),
      renderHeader: (params) => {
        return (
          <ColHeader
            text={t('The reporting entity for the supplier company.')}
            title={params.colDef.headerName}
          />
        );
      },
    },
    {
      field: 'signupDate',
      headerName: `${t('Signup')}`,
      width: 115,
      align: 'center',
      sortable: false,
      disableColumnMenu: true,
      renderHeader: (params) => {
        return (
          <ColHeader
            title={params.colDef.headerName}
            text={t(
              'Signup date of the first user for this supplier. If empty, no users have signed up yet.'
            )}
          />
        );
      },
    },
    {
      field: 'lastScreened',
      headerName: `${t('Screening')}`,
      width: 125,
      align: 'center',
      sortable: false,
      disableColumnMenu: true,
      renderHeader: (params) => {
        return (
          <ColHeader
            title={params.colDef.headerName}
            text={t(
              'Date of when publicly available information of this company was collected most recently.'
            )}
          />
        );
      },
    },
    {
      field: 'crcLevel',
      headerName: `${t('CRC Status')}`,
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      align: 'center',
      disableColumnMenu: true,
      width: 138,
      renderHeader: (params) => {
        return (
          <ColHeader
            title={params.colDef.headerName}
            text={t(
              `Not started” means they have signed up but not yet started the Climate Readiness Check. “In progress” means that they have started but not yet completed the Check. Otherwise this column will show the Level that each company was awarded after completion.`
            )}
          />
        );
      },
    },
    {
      field: 'climateScore',
      headerName: `${t('Climate Score')}`,
      sortable: false,
      align: 'right',
      disableColumnMenu: true,
      width: 130,
      renderHeader: (params) => {
        return (
          <ColHeader
            title={params.colDef.headerName}
            text={t(
              "The Climate Score is a streamlined, comprehensive rating that combines trusted public data with company-provided information to gauge a company's climate performance in key categories, such as emissions, strategy, and decarbonization measures"
            )}
          />
        );
      },
    },
    {
      field: 'actions',
      type: 'actions',
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          icon={<DeleteOutlined />}
          onClick={() => {
            if (
              confirm(
                t(
                  'Important Notice: Removing this supplier will also delete their data from all future exports. To preserve your records, we advise exporting your supplier list beforehand. Are you certain you wish to delete {{name}} from your list of suppliers?',
                  { name: params.row.supplierName }
                )
              )
            ) {
              mutate(params.row.supplierId);
            }
          }}
          label={t('Remove')}
        />,
      ],
    },
  ];

  const rows = suppliers
    .filter((supplier) => !!supplier.id)
    .map((supplier, index) => {
      return {
        id: supplier.id,
        logo: supplier.logo,
        supplierName: supplier.name,
        supplierId: supplier.id,
        supplierSlug: supplier.slug,
        supplierReportingCompanyName: supplier.reporting_company_name,
        supplierReportingCompanySlug: supplier.reporting_company_slug,
        hasExportableCRC: supplier.has_exportable_crc,
        hasProvidedAccess: supplier.has_provided_access,
        signupDate:
          supplier.user_signup_date === 'Data access not provided' ||
          supplier.user_signup_date === 'Datenzugang nicht vorhanden'
            ? ' '
            : supplier.user_signup_date
            ? dateReadable(supplier.user_signup_date)
            : null,
        lastScreened: supplier.last_screened
          ? dateReadable(supplier.last_screened)
          : null,
        cpaStatus: {
          status: supplier.survey?.status,
          expires_soon: supplier.survey?.expires_soon,
        },
        climateScore:
          supplier.climate_score === null ||
          supplier.climate_score === undefined
            ? t('N/A')
            : getClimateScoreAsPercent(Number(supplier.climate_score)),
        visibility: supplier.published,
        exportLink: supplier.has_exportable_crc ? t('export data') : t('N/A'),
        crcLevel:
          //need alignment with BE
          supplier.crc_result,
      };
    });

  const CustomFooterComp = () => {
    return (
      <CustomTableFooter
        slug={slug}
        suppliersCount={suppliersCount}
        onClick={ClearSelection}
        rowsPerPage={rowsPerPage}
      />
    );
  };

  return (
    <Box>
      {suppliersCount !== null && suppliersCount !== 0 ? (
        <div style={{ height: `${height}px` }}>
          {/* <SelectCard
            companiesNumber={comapniesSelectedLength}
            companiesSlug={selectedCompaniesSlug as Array<string>}
            onClickClearSelection={ClearSelection}
          /> */}
          <StyledDataGrid
            sx={{
              '& .MuiDataGrid-cell': {
                display: 'flex',
                alignItems: 'center',
              },
            }}
            rows={rows}
            columns={columns}
            // pageSizeO={rowsPerPage}
            pageSizeOptions={[rowsPerPage]}
            // checkboxSelection
            isRowSelectable={(params) => {
              return params.row.hasExportableCRC;
            }}
            disableRowSelectionOnClick
            slots={{
              pagination: CustomFooterComp,
            }}
            style={{ borderStyle: 'none' }}
            // onSelectionModelChange={(newSelection) => {
            //   let selectedCompaniesSlugList = [] as GridSelectionModel;
            //   newSelection.map((rowNumber: any) => {
            //     return selectedCompaniesSlugList.push(
            //       rows[rowNumber].supplierSlug
            //     );
            //   });
            //   setComapniesSelectedLength(newSelection.length);
            //   setSelectedCompaniesSlug(selectedCompaniesSlugList);
            //   setSelectionModel(newSelection);
            // }}
            rowSelectionModel={selectionModel}
          />
        </div>
      ) : (
        <Typography variant="subtitle1" color={colors.neutral600}>
          {t('0 results found')}
        </Typography>
      )}
    </Box>
  );
}
